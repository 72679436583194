<template>
    <div class="popup-item login">
        <h4 class="popup-item__title">Вход</h4>
        <p class="popup-item__subtitle">
            укажите Ваш e-mail для доступа ко всем бонусам
        </p>
        <div class="popup-item__form">
            <Input :value="user.email" 
                    type="email"
                    placeholder="Эл. почта" 
                    white 
                    :onChangeHandler="val=>{handleInput(val, 'email')}"
                    :styles="style"/>
        </div>
        <div class="login__button">
            <span class="login__button-switch" @click="$emit('switch', 'Registration')">Нет аккаунта? Зарегистрируйтесь!</span>
            <Button :click="emitLogin" :styles="{'font-weight': 600, 'height': '60px', 'width': '290px'}">Вход</Button>
        </div>

    </div>
</template>

<script>
import {popupMixin} from "../../mixins/popup"
import Input from "./Input"
import Button from "./Button"
export default {
    data() {
        return {
            style: {
                'width': "400px",
                'margin': "0 auto"
            },
            user: {
                name: "",
                email: ""
            }
        }
    },
    mixins: [popupMixin],
    components: {
        Input, Button
    },
    methods: {
        handleInput(value, key) {
            this.user[key] = value
        },
        emitLogin(){
            this.$emit('login', this.user)
        }
    }
}
</script>

<style lang="scss">
    .login {
        &__button {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            position: relative;

            &-switch {
                position: absolute;
                cursor: pointer;
                font-size: 14px;
                top: -30px;
                left: 0;
                right: 0;
                text-align: center;
                color: #AF5B4C;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>